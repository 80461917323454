<template>
  <div>
    <div class="header">
      <div class="registheader" @click="topageshome">
        <img class="logopng" src="../assets/img/logoad.png" alt="" />
      </div>
    </div>
    <div class="registcenter">
      <el-steps :active="active" align-center>
        <el-step title="验证手机号"></el-step>
        <el-step title="填写账号信息"></el-step>
        <el-step title="注册成功"></el-step>
      </el-steps>
      <div class="formcenter" v-if="active == 0">
        <el-input
          placeholder="请输入手机号"
          v-model="phones"
          maxlength="11"
          @input="inputPhone"
          clearable
        >
          <template slot="prepend"
            ><img src="../assets/img/hsouji.png"
          /></template>
        </el-input>
        <div style="height: 39px"></div>
        <el-input
          placeholder="请输入验证码"
          v-model="code"
          maxlength="6"
          clearable
        >
          <template slot="prepend"
            ><img src="@/assets/img/yaznhegma.png"
          /></template>
          <template slot="append">
            <el-button
              type="primary"
              size="small"
              :disabled="desides"
              @click="enxttop"
              >{{ textsnum }}</el-button
            ></template
          >
        </el-input>
      </div>
      <div class="formcenter" v-if="active == 1">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item>
						<el-input placeholder="请填写昵称" clearable v-model="ruleForm.nickName">
						  <template slot="prepend">
						    <img style="width: 18px" src="../assets/img/usernake.png" />
						  </template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-input placeholder="请填写姓名" clearable v-model="ruleForm.realName">
						  <template slot="prepend">
						    <img style="width: 18px" src="../assets/img/usernake.png" />
						  </template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<div style="display: flex;align-items: center;">
							<div class="formsex">
								<img style="width: 18px" src="../assets/img/xie/sex.png" />
							</div>
							<el-select v-model="ruleForm.sex" placeholder="请选择性别" style="width: 440px;">
								<el-option label="男" value="1"></el-option>
								<el-option label="女" value="0"></el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item prop="age">
						<el-input placeholder="请填写年龄" maxlength="3" v-model="ruleForm.age">
						  <template slot="prepend">
						    <img style="width: 18px" src="../assets/img/xie/age.png" />
						  </template>
						</el-input>
					</el-form-item>
					<el-form-item prop="linkPhone">
						<el-input placeholder="请填写联系电话" maxlength="11" clearable v-model="ruleForm.linkPhone">
						  <template slot="prepend">
						    <img src="../assets/img/hsouji.png" />
						  </template>
						</el-input>
					</el-form-item>
					<el-form-item prop="linkEmail">
						<el-input placeholder="请填写联系邮箱" clearable v-model="ruleForm.linkEmail">
						  <template slot="prepend">
						    <img style="width: 18px" src="../assets/img/xie/email.png" />
						  </template>
						</el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input placeholder="请设置包含字母和数字,且在6~12位之间的密码" show-password v-model="ruleForm.password">
						  <template slot="prepend">
						    <img src="../assets/img/mima.png" />
						  </template>
						</el-input>
						<div style="color: red; margin-left: 5px;">*</div>
					</el-form-item>
					<el-form-item prop="newpassword">
						<el-input placeholder="再次确认密码" show-password v-model="ruleForm.newpassword">
						  <template slot="prepend">
						    <img src="../assets/img/mima.png" />
						  </template>
						</el-input>
						<div style="color: red; margin-left: 5px;">*</div>
					</el-form-item>
				</el-form>
      </div>
      <div class="formcenter" v-if="active >= 2">
        <img
          style="width: 162px; height: 158px; margin-top: 60px"
          src="../assets/img/qiu.png"
          alt=""
        />
        <div class="texts">注册成功，快去登录吧！</div>
      </div>
      <div class="btns" @click="next(1)" v-if="active > 2">立即登录</div>
      <div class="btns" @click="next(0)" v-if="active== 0">下一步</div>
			<div class="btns" @click="submitForm('ruleForm')" v-if="active== 1">下一步</div>
    </div>
  </div>
</template>

<script>
import {
  getPhoneCode,
  postUserRegister,
  getUserInfoByPhone,
	getCodesoue
} from "../request/homeApi";
export default {
  data() {
		var ages = (rule, value, callback) => {
			let reg = /^([1-9]\d{0,1}|100)$/;
				if (value === '') {
					callback();
				} else if(!reg.test(value)){
					callback(new Error('年龄只能是1-100的数字!'));
				}else{
					callback();
				}
      };
			var linkPhones = (rule, value, callback) => {
				let mobile = /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/;
					if (value === '') {
						callback();
					} else if(!mobile.test(value)){
						callback(new Error('请输入正确手机号码'));
					}else{
						callback();
					}
			  };
				var linkEmails = (rule, value, callback) => {
				let email = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
					if (value === '') {
						callback();
					} else if(!email.test(value)){
						callback(new Error('请输入正确的邮箱地址'));
					}else{
						callback();
					}
				};
			var validatePass = (rule, value, callback) => {
				let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
        if (value === '') {
          callback(new Error('请输入密码'));
        } else if(!regex.test(value)){
          callback(new Error('密码必须包含字母和数字,且在6~12位之间'));
        }else{
					callback();
				}
      };
			var newpasswords = (rule, value, callback) => {
			  if (value === '') {
			    callback(new Error('请确认密码'));
			  } else if(value!=this.ruleForm.password){
			    callback(new Error('两次密码不一致'));
			  }else{
					callback();
				}
			};
    return {
      active: 0,
      textsnum: "获取验证码",
      phones: "",
      code: "",
      username: "",
      passwordone: "",
      passwordtwo: "",
      numbers: 60,
      desides: false,
      timer: "",
      type: "",
      uid: "",
      verificationEnums: "REGISTER",
      unid: "",
      texts: "",
      textstwo: "",
			ruleForm:{
				nickName:'',
				realName:'',
				sex:'0',
				age:'',
				linkPhone:'',
				linkEmail:'',
				password:'',
				newpassword:''
			},
			rules:{
				age:[{ required: false, validator: ages, trigger: 'blur' }],
				linkPhone:[{ required: false, validator: linkPhones, trigger: 'blur' }],
				linkEmail:[{ required: false, validator: linkEmails, trigger: 'blur' }],
				password:[{ required: true, validator: validatePass, trigger: 'blur' }],
				newpassword:[{ required: true, validator: newpasswords, trigger: 'blur' }],
			}
    };
  },
  mounted() {
    if (this.$route.query.uid) {
      this.unid = this.$route.query.unid;
      this.uid = this.$route.query.uid;
      this.type = "WECHAT_PC";
      this.verificationEnums = "BIND";
    }
  },
  methods: {
    inputPhone(e) {
      const Integer = /^[0-9]\d*$/;
      if (!Integer.test(e)) {
        this.phones = "";
        this.$message.error("请输入正确手机号码");
        return;
      }
    },
    blursad() {
      let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;

      if (!this.passwordone) {
        this.texts = "请输入密码";
      } else if (!regex.test(this.passwordone)) {
        this.texts = "密码必须包含字母和数字,且在6~12位之间";
      } else {
        this.texts = "";
      }
    },
    blurtwos() {
      if (this.passwordtwo !== this.passwordone) {
        this.textstwo = "两次密码不一致";
      }else{
        this.textstwo ='';
      }
    },
    getTime() {
      this.timer = setInterval(() => {
        this.numbers--;
        this.textsnum = "剩余" + this.numbers + "s";
        if (this.numbers == 0) {
          this.textsnum = "获取验证码";
          this.desides = false;
          this.numbers = 60;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    enxttop() {
      const mobile =
        /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/;
      if (!mobile.test(this.phones)) {
        this.$message.error("请输入正确手机号码");
        return;
      }
      let strings = "";
      if (this.verificationEnums == "BIND") {
        strings = ",WECHAT_PC";
      }
      let param = {
        mobile: this.phones + strings,
        verificationEnums: this.verificationEnums,
        uuid: "asdf"
      };
      getPhoneCode(param)
        .then((res) => {
          if (res.code) {
            this.$message.success("获取验证码成功");
            this.desides = true;
            this.textsnum = "剩余" + this.numbers + "s";
            this.getTime();
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    topageshome(){
      this.$router.push({ path: "/" });
    },
    next(index) {
      if (index == 0) {
        if (!this.code) {
          this.$message.error("请填写手机验证码");
          return false;
        }
				let iscode=false
				let codesa={
					mobile:this.phones,
					code:this.code,
					type:this.verificationEnums
				}
				getCodesoue(codesa).then((res)=>{
					if(res.code==200){
						iscode=true
					}
				})
				setTimeout(()=>{
					if(iscode){
						getUserInfoByPhone(this.phones).then((res) => {
						    console.log(res.result);
						    if (res.code == 200 && res.result == null) {
						      this.active = 1;
									this.ruleForm.linkPhone=this.phones
						      return;
						    } else {
						      let data = {
						        mobilePhone: this.phones,
						        password: "",
						        newpassword: "",
						        username: "",
						        code: this.code,
						        type: this.type,
						        uid: this.uid,
						        unid: this.unid,
						      };
						      postUserRegister(data).then((res) => {
						          console.log(res);
						          if (res.code == 200) {
						            this.$message.success("注册成功");
						            this.active = 3;
						            return;
						          }
						        }).catch((err) => {
						          console.log(err);
						        });
						    }
						  }).catch((err) => {
						    console.log(err);
						  });
					}
				},200)
      } else {
        this.$router.push({ path: "/login" });
      }
    },
		submitForm(formName){
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					  let data = {
					    mobilePhone: this.phones,
					    password: this.$md5(this.ruleForm.password),
					    newpassword: this.$md5(this.ruleForm.newpassword),
					    nickName: this.ruleForm.nickName,
							realName:this.ruleForm.realName,
							sex:this.ruleForm.sex,
							username:'',
							age:this.ruleForm.age?this.ruleForm.age:0,
							linkPhone:this.ruleForm.linkPhone,
							linkEmail:this.ruleForm.linkEmail,
					    code: this.code,
					    type: this.type,
					    uid: this.uid,
					    unid: this.unid,
					  };
					  postUserRegister(data)
					    .then((res) => {
					      console.log(res);
					      if (res.code == 200) {
					        this.$message.success("注册成功");
					        this.active = 3;
					      }
					    })
					    .catch((err) => {
					      console.log(err);
					    });
				}
			});
		}
  },
};
</script>

<style lang="scss" scoped>
.header {
  box-shadow: 0px 10px 8px 0px rgba(225, 225, 225, 0.27);
  .registheader {
    cursor: pointer;
    width: 1250px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .logopng {
      width: 310px;
      height: 53px;
    }
  }
}
.registcenter {
  width: 480px;
  margin: 0 auto;
  margin-top: 74px;
  .formcenter {
    margin-top: 35px;
    text-align: center;
    .texts {
      margin-top: 38px;
      font-size: 18px;
      color: #535353;
    }
		.formsex{
			width: 40px;
			height: 40px;
			background-color: #F5F7FA;
			border-top: 1px solid #DCDFE6;
			border-left: 1px solid #DCDFE6;
			border-bottom: 1px solid #DCDFE6;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}
		::v-deep .el-select{
			.el-input__inner{
				border-top-left-radius: 0px !important;
				border-bottom-left-radius: 0px !important;
			}
		}
		::v-deep .el-form-item__content{
			display: flex;
		}
  }
  .btns {
    cursor: pointer;
    margin-top: 42px;
    width: 100%;
    height: 54px;
    text-align: center;
    line-height: 54px;
    background: #00a3e0;
    font-size: 18px;
    color: #ffffff;
  }
}
::v-deep .el-input-group__prepend {
  padding: 0;
  width: 40px;
  text-align: center;
}
</style>